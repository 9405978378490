import { Module, getModule, Action } from 'vuex-module-decorators';
import store from '@/admin/store';
import PageBaseModule from '@/admin/store/page';
import { getUserQuestionList } from '@/admin/api/questions';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';
import TabsNav from './tabs';
import { tabsNavInterface } from '@/interfaces/tabs';

const MODULE_NAME = 'userQuestions';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class UserQuestionsModule extends PageBaseModule {
    tabsNav: tabsNavInterface;

    constructor(module: UserQuestionsModule) {
        super(module);

        this.tabsNav = TabsNav;
        const page = new PageEntity();
        page.setTitle('');
        this.pageSettings = page.values;
    }

    @Action({ rawError: true })
    init() {
        this.getTable();
    }

    @Action({ rawError: true })
    updatePage(number: string) {
        this.context.commit('SET_PAGE', parseInt(number));
        this.getTable();
    }

    @Action({ rawError: true })
    async getTable() {
        this.SET_IS_LOADING(true);

        const sort = await this.getSortForRequest();

        const result = await getUserQuestionList(
            this.pageSettings.pageCurrent,
            sort,
            '',
            this.pageSettings.pageAmountItems,
        );
        this.SET_TABLE(await this.prepareTable(result));

        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    prepareTable(table: TableApiInterface): TableInterface {
        const hiddenColumns = table.titles.filter((title) => !title.visible).map((title) => title.id);
        const rows: any = [];

        let index: string;
        let row: any;
        for ([index, row] of Object.entries(table.rows)) {
            const rowProcessed: any = {};

            rows[index] = rowProcessed;
        }

        return {
            titles: table.titles,
            rows: rows,
            sortFields: [],
            pagination: table.pagination,
            totalItems: table.totalItems,
            filters: {},
            hiddenColumns: hiddenColumns,
        } as TableInterface;
    }

    @Action({ rawError: true })
    reset() {
        this.RESET_TABLE();
    }
}
export default getModule(UserQuestionsModule);
