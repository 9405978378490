
import { Component, Vue } from 'vue-property-decorator';
import TableBlock from '@/components/table/Table.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import Loading from '@/components/Loading.vue';
import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import DropdownBlock from '@/components/DropdownWrapper.vue';

import UserQuestionsModule from '@/admin/store/incoming_requests';
import ResponseHandlerModule from '@/admin/store/modules/responseHandler';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { postUserQuestionAnswer } from '@/admin/api/questions';
import { getApiErrorMessage } from '@/lib/utils/Utils';

library.add(faChevronRight, faChevronLeft);

@Component({
    components: {
        TableBlock,
        TabsNav,
        Loading,
        BtnSubmit,
        DropdownBlock,
        FontAwesomeIcon,
    },
})
export default class UserQuestionsListComponent extends Vue {
    activeDropdown = 0;

    get settings() {
        return UserQuestionsModule.pageSettings;
    }

    get isLoading() {
        return UserQuestionsModule.isLoading;
    }

    get paginationAction() {
        return UserQuestionsModule.updatePage;
    }

    toggleDropdown(id: number): void {
        if (id === this.activeDropdown) {
            this.activeDropdown = 0;

            return;
        }

        this.activeDropdown = id;
    }

    async sendResponse(questionId: number): Promise<void> {
        const elem = this.$el.querySelector(`#textarea_${questionId}`) as HTMLTextAreaElement;

        if (!elem.value) {
            return;
        }

        try {
            const result = await postUserQuestionAnswer(questionId, { answer: elem.value });
            if (result === 'ok') {
                ResponseHandlerModule.showNotify({ message: 'Ответ успешно отправлен', type: 'ok' });
            }
        } catch (error) {
            const message = getApiErrorMessage(error);
            ResponseHandlerModule.showNotify({ message, type: 'ok' });
        }
    }

    async mounted() {
        await UserQuestionsModule.init();
    }
}
