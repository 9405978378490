import { makeGetRequest } from '@/api/helpers';
import API from './../config/api-routers';

export const getSaleOfferList = async (currentPage: number, sort: string, filter: string, items: string) => {
    const result = await makeGetRequest(`${API.sale_offer.getList}?page=${currentPage}${sort}${filter}&items=${items}`);

    return result.data.result.table;
};

export const getSaleOfferCard = async (id: number) => {
    const result = await makeGetRequest(`${API.sale_offer.card}${id}/`);

    return result.data.result.item;
};
