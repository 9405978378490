
import { Vue, Component } from 'vue-property-decorator';
import Layout from '@/admin/layouts/Default.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import Questions from './_questions.vue';
import QuestionsAuctionHouses from './_questionsAuctionHouses.vue';
import QuestionsGalleries from './_questionsGalleries.vue';
import QuestionsPainters from './_questionsPainters.vue';
import QuestionsSell from './_questionsSell.vue';

import questionsModule from '@/admin/store/incoming_requests';
import { tabsNavInterface } from '@/interfaces/tabs';

@Component({
    components: {
        Layout,
        TitleReturn,
        TabsNav,
        Questions,
        QuestionsAuctionHouses,
        QuestionsGalleries,
        QuestionsPainters,
        QuestionsSell,
    },
})
export default class RequestsComponent extends Vue {
    title = 'Входящие запросы';

    get tabsNav(): tabsNavInterface {
        return questionsModule.tabsNav;
    }

    get currentTab(): string {
        if (this.$route.name === 'question_sell') {
            return questionsModule.tabsNav['questions_sell'].id;
        }

        return questionsModule.tabsNav[this.$route.name as string].id;
    }
}
