import { makeFormRequest, makeGetRequest } from '@/api/helpers';
import API from './../config/api-routers';

export const getUserQuestionList = async (currentPage: number, sort: string, filter: string, items: string) => {
    const result = await makeGetRequest(
        `${API.user_question.getList}?page=${currentPage}${sort}${filter}&items=${items}`,
    );

    return result.data.result.table;
};

export const getUserQuestionCard = async (id: number) => {
    const result = await makeGetRequest(`${API.user_question.card}${id}/`);

    return result.data.result.item;
};

export const postUserQuestionAnswer = async (id: number, data: { answer: string }): Promise<string> => {
    const result = await makeFormRequest(`${API.user_question.card}${id}/answer/`, data);

    return result.data.result.status;
};
