export default {
    questions: {
        id: 'questions',
        name: 'Вопросы от пользователей',
        template: 'questions',
        pathName: 'questions',
        icon: '',
        iconSize: '16',
    },
    questions_auction_houses: {
        id: 'questions_auction_houses',
        name: 'Вопросы от АД',
        template: 'questions_auction_houses',
        pathName: 'questions_auction_houses',
        icon: '',
        iconSize: '16',
    },
    questions_galleries: {
        id: 'questions_galleries',
        name: 'Вопросы от галерей',
        template: 'questions_galleries',
        pathName: 'questions_galleries',
        icon: '',
        iconSize: '16',
    },
    questions_painters: {
        id: 'questions_painters',
        name: 'Вопросы от художников',
        template: 'questions_painters',
        pathName: 'questions_painters',
        icon: '',
        iconSize: '16',
    },
    questions_sell: {
        id: 'questions_sell',
        name: 'Предложения о продаже',
        template: 'questions_sell',
        pathName: 'questions_sell',
        icon: '',
        iconSize: '16',
    },
};
