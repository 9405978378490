
import { Component, Vue, Prop } from 'vue-property-decorator';
import PaginationBlock from '@/components/Pagination.vue';

interface PaginationInterface {
    currentPage: number;
    totalPages: number;
    totalItems: number;
}

@Component({
    components: {
        PaginationBlock,
    },
})
export default class DropdownWrapperComponent extends Vue {
    activeDropdown = 0;
    @Prop({ required: false }) readonly pagination!: PaginationInterface;
    @Prop({ required: false }) readonly action!: (num: string | number) => void;

    toggleDropdown(id: number): void {
        if (id === this.activeDropdown) {
            this.activeDropdown = 0;

            return;
        }

        this.activeDropdown = id;
    }
}
