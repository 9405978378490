
import { Vue, Component } from 'vue-property-decorator';

import Loading from '@/components/Loading.vue';
import DropdownBlock from '@/components/DropdownWrapper.vue';

import SaleOfferModule from '@/admin/store/incoming_requests/saleOffer';
import { PageInterface } from '@/lib/layouts/page/page.interface';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

library.add(faChevronRight, faChevronLeft);

@Component({
    components: {
        Loading,
        FontAwesomeIcon,
        DropdownBlock,
    },
})
export default class SaleOfferComponent extends Vue {
    activeDropdown = 0;

    get settings(): PageInterface {
        return SaleOfferModule.pageSettings;
    }

    get isLoading(): boolean {
        return SaleOfferModule.isLoading;
    }

    get paginationAction() {
        return SaleOfferModule.updatePage;
    }

    toggleDropdown(id: number): void {
        if (id === this.activeDropdown) {
            this.activeDropdown = 0;

            return;
        }

        this.activeDropdown = id;
    }

    mounted(): void {
        SaleOfferModule.init();
    }
}
